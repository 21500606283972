// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Font awesome
@import '~@fortawesome/fontawesome-free/css/all.min.css';

//Select2
@import '~select2/dist/css/select2.min.css';

//Sweetalert 2
@import '~sweetalert2/src/sweetalert2';

//ChartJS
@import '~chart.js/dist/Chart.min.css';

//Date range picker
@import '~daterangepicker/daterangepicker.css';

html{
    scroll-behavior: smooth;
}
.table-middle > tbody > tr > td {
    vertical-align: middle;
}
.custom-file{
    overflow: hidden;
}
.mh-400{
    max-height: 400px;
}
.nav-pills .nav-item .nav-link.disabled {
    color: #6c757d !important;
    opacity: 0.6;
}

textarea.form-control {
    height: 64px !important;
}
.td-link{
    cursor: pointer;
}
.fc-content{
    color: #fff!important;
    font-size: 14px!important;
    font-weight: bold!important;
}
.handle {
    cursor: move;
}
textarea.textarea-height{
    height: 350px !important;
}
.main-sidebar .sidebar-menu li a span{
    font-weight: 600;
    color: #000;
    font-size: 15px !important;
}

.main-sidebar .sidebar-menu li a.has-dropdown span{
    color: #223cff !important;
}
